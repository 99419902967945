import React from 'react';
import { Text, VStack, Switch, FormLabel, HStack, Divider, Button, Center } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon
import WhatsAppSection from './WhatsAppSection';

interface SidebarContentProps {
  isOpen: boolean;
  toggleEnglishScript: () => void;
  toggleEnglishTranslation: () => void;
  toggleEnglishExplanation: () => void;
  togglePunjabiExplanation: () => void;
  showEnglishExplanation: boolean;
  showPunjabiExplanation: boolean;
}

const SidebarContent: React.FC<SidebarContentProps> = ({
  isOpen,
  toggleEnglishScript,
  toggleEnglishTranslation,
  toggleEnglishExplanation,
  togglePunjabiExplanation,
  showPunjabiExplanation,
  showEnglishExplanation
}) => {
  return (
    <VStack
      as="nav"
      spacing={4}
      align="stretch"
      flexShrink={0}
      w={{ base: isOpen ? 'full' : 0, md: 60 }}
      display={{ base: isOpen ? 'flex' : 'none', md: 'flex' }}
      px={4}
      py={8}
    >
      <HStack justifyContent="space-between" w="full" px={4}>
        <FormLabel htmlFor="show-english-script" mb="0">
          Show English Transliteration
        </FormLabel>
        <Switch id="show-english-script" onChange={toggleEnglishScript} />
      </HStack>

      <HStack justifyContent="space-between" w="full" px={4}>
        <FormLabel htmlFor="show-english-translation" mb="0">
          Show English Translation
        </FormLabel>
        <Switch id="show-english-translation" onChange={toggleEnglishTranslation} />
      </HStack>

      <HStack justifyContent="space-between" w="full" px={4}>
        <FormLabel htmlFor="show-english-explanation" mb="0">
          Show English Explanation
        </FormLabel>
        <Switch id="show-english-explanation" onChange={toggleEnglishExplanation} isChecked={showEnglishExplanation} />
      </HStack>

      <HStack justifyContent="space-between" w="full" px={4}>
        <FormLabel htmlFor="show-punjabi-explanation" mb="0">
          Show Punjabi Explanation
        </FormLabel>
        <Switch id="show-punjabi-explanation" onChange={togglePunjabiExplanation} isChecked={showPunjabiExplanation} />
      </HStack>
      <WhatsAppSection/>
   
    </VStack>
  );
};

export default SidebarContent;










import React, { useState, useEffect } from 'react';
import { Box, Center, Spinner, Text } from '@chakra-ui/react';
import { fetchJapjiSahib } from './FirebaseService'; // Adjust the import path as necessary
import { Verse } from './Verse';
import VerseDisplayer from './VerseDisplayer'; // Make sure the import path is correct
import WhatsAppSection from './WhatsAppSection';

// In MainContent.tsx

interface MainContentProps {
  showEnglishScript: boolean;
  showEnglishTranslation: boolean;
  showEnglishExplanation: boolean;
  showPunjabiExplanation: boolean;
}

const MainContent: React.FC<MainContentProps> = ({
  showEnglishScript,
  showEnglishTranslation,
  showEnglishExplanation,
  showPunjabiExplanation,
}) => {
  const [verses, setVerses] = useState<Verse[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const docs = await fetchJapjiSahib();
        const sortedDocs = docs.sort((a, b) => a.order_in_list - b.order_in_list);
        setVerses(sortedDocs);
      } catch (error) {
        console.error("Error fetching Japji Sahib documents:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box bg="#F7FAFC" p={5} flex={1} borderRadius="md" boxShadow="sm">
      {verses.length > 0 ? (
        verses.map((verse, index) => (
          <VerseDisplayer
            key={index}
            verse={verse}
            showEnglishScript={showEnglishScript}
            showEnglishTranslation={showEnglishTranslation}
            showEnglishExplanation={showEnglishExplanation}
            showPunjabiExplanation={showPunjabiExplanation}
          />
        ))
      ) : (
        <Center>
          <Spinner size="xl" /> {/* You can adjust the size as needed */}
        </Center>
      )}
      <WhatsAppSection />
    </Box>
  );
};

export default MainContent;

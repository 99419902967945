import React from 'react';
import { Box, Center, Text, Collapse } from '@chakra-ui/react';
import { Verse } from './Verse';

interface VerseDisplayerProps {
  verse: Verse;
  showEnglishScript: boolean;
  showEnglishTranslation: boolean;
  showEnglishExplanation: boolean;
  showPunjabiExplanation: boolean;
}

const VerseDisplayer: React.FC<VerseDisplayerProps> = ({
  verse,
  showEnglishScript,
  showEnglishTranslation,
  showEnglishExplanation,
  showPunjabiExplanation,
}) => {
  return (
    <Box mb={4}>
      <Center>
        <Text fontSize="2xl" fontWeight="extrabold" color="orange.500" mb={4}>
          {verse.original}
        </Text>
      </Center>
      <Collapse in={showEnglishScript}>
        <Box bg="gray.100" p={2} borderRadius="md">
          <Text fontSize="sm" fontWeight="bold">{verse.english_script}</Text>
        </Box>
      </Collapse>
      <Collapse in={showEnglishTranslation} style={{ marginTop: showEnglishTranslation ? '0.5rem' : '0' }}>
        <Box bg="gray.100" p={2} borderRadius="md">
          <Text fontSize="sm" fontWeight="bold">{verse.english_translation}</Text>
        </Box>
      </Collapse>
      <Collapse in={showEnglishExplanation} style={{ marginTop: showEnglishExplanation ? '0.5rem' : '0' }}>
        <Box bg="gray.100" p={2} borderRadius="md">
          <Text fontSize="sm" fontWeight="bold">{verse.simple_english_explanation}</Text>
        </Box>
      </Collapse>
      <Collapse in={showPunjabiExplanation} style={{ marginTop: showPunjabiExplanation ? '0.5rem' : '0' }}>
        <Box bg="gray.100" p={2} borderRadius="md">
          <Text fontSize="sm" fontWeight="bold">{verse.simple_punjabi_explanation}</Text>
        </Box>
      </Collapse>
    </Box>
  );
};

export default VerseDisplayer;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Verse } from './Verse';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyBON4HCpc7ZKZWc8qpIxsui8-kdr2k8pjY",
    authDomain: "gurbanisimplified-b0f20.firebaseapp.com",
    projectId: "gurbanisimplified-b0f20",
    storageBucket: "gurbanisimplified-b0f20.appspot.com",
    messagingSenderId: "1051541762273",
    appId: "1:1051541762273:web:27ef1254198f4b8a0287a0",
    measurementId: "G-XD4C6QEZ3J"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export const fetchJapjiSahib = async (): Promise<Verse[]> => {
    const japjiSahibCollection = collection(db, 'JapjiSahib');
    try {
      const querySnapshot = await getDocs(japjiSahibCollection);
      const documents: Verse[] = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          original: data.original,
          english_script: data.english_script,
          english_translation: data.english_translation,
          simple_english_explanation: data.simple_english_explanation,
          simple_punjabi_explanation: data.simple_punjabi_explanation,
          order_in_list: data.order_in_list,
          bani: data.bani
        };
      });
      return documents;
    } catch (error) {
      console.error("Error fetching documents from 'JapjiSahib' collection:", error);
      throw new Error("Failed to fetch documents");
    }
  };

import React from 'react';
import { Divider, Text, Center, Button } from '@chakra-ui/react';
import { FaWhatsapp } from 'react-icons/fa'; // Ensure you have imported FaWhatsapp

const WhatsAppSection = () => {
  return (
    <>
      <Divider my={4} borderColor="gray.600" />

      <Text
        textAlign="center"
        px={2}
        fontSize="xs"
        color="gray.700"
        mt={4}
        mb={2}
      >
        Stay updated with new explanations being updated weekly, and join the discussions in the 'Gurbani Explained' WhatsApp group.
      </Text>

      <Text
        textAlign="center"
        px={2}
        fontSize="xs"
        color="gray.700"
        mb={4}
      >
        ਹਫਤਾਵਾਰੀ ਅੱਪਡੇਟ ਕੀਤੇ ਜਾ ਰਹੇ ਨਵੇਂ ਸਪੱਸ਼ਟੀਕਰਨਾਂ ਨਾਲ ਅੱਪਡੇਟ ਰਹੋ, ਅਤੇ 'ਗੁਰਬਾਣੀ ਵਿਆਖਿਆ' WhatsApp ਗਰੁੱਪ ਵਿੱਚ ਚਰਚਾਵਾਂ ਵਿੱਚ ਸ਼ਾਮਲ ਹੋਵੋ।
      </Text>

      <Center>
        <Button
          as="a"
          href="https://chat.whatsapp.com/K3tFkMsbSbhBdk7nmeJDV6" // Make sure to replace this with your actual invite link
          colorScheme="whatsapp"
          leftIcon={<FaWhatsapp />}
        >
          Gurbani Explained
        </Button>
      </Center>
    </>
  );
};

export default WhatsAppSection;

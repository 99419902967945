import React, { useState } from 'react';
import {
  Box,
  Flex,
  IconButton,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { FiMenu, FiX } from 'react-icons/fi';
import MainContent from './MainContent';
import SidebarContent from './SidebarContent';

const Sidebar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showEnglishScript, setShowEnglishScript] = useState(false);
  const [showEnglishTranslation, setShowEnglishTranslation] = useState(false);
  const [showEnglishExplanation, setShowEnglishExplanation] = useState(true);
  const [showPunjabiExplanation, setShowPunjabiExplanation] = useState(true);

  const toggleEnglishScript = () => setShowEnglishScript(!showEnglishScript);
  const toggleEnglishTranslation = () => setShowEnglishTranslation(!showEnglishTranslation);
  const toggleEnglishExplanation = () => setShowEnglishExplanation(!showEnglishExplanation);
  const togglePunjabiExplanation = () => setShowPunjabiExplanation(!showPunjabiExplanation);

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} padding={5}>
      <Flex minHeight="100vh" direction={{ base: 'column', md: 'row' }}>
        {/* Mobile menu button */}
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
          icon={isOpen ? <FiX /> : <FiMenu />}
          aria-label="Toggle Sidebar"
        />
        {/* SidebarContent with fixed position for non-mobile screens */}
        <Box display={{ base: isOpen ? 'block' : 'none', md: 'block' }} position={{ md: 'fixed' }}>
          <SidebarContent
            isOpen={isOpen}
            toggleEnglishScript={toggleEnglishScript}
            toggleEnglishTranslation={toggleEnglishTranslation}
            toggleEnglishExplanation={toggleEnglishExplanation}
            togglePunjabiExplanation={togglePunjabiExplanation}
            showEnglishExplanation={showEnglishExplanation}
            showPunjabiExplanation={showPunjabiExplanation}
          />
        </Box>
        {/* MainContent with scrollable content */}
        <VStack flex="1" overflowY="auto" marginLeft={{ md: '250px' }}> {/* Adjust marginLeft based on Sidebar width */}
          <MainContent
            showEnglishScript={showEnglishScript}
            showEnglishTranslation={showEnglishTranslation}
            showEnglishExplanation={showEnglishExplanation}
            showPunjabiExplanation={showPunjabiExplanation}
          />
        </VStack>
      </Flex>
    </Box>
  );
};

export default Sidebar;

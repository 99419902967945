import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App'; // Assuming your main component is in App.tsx
import Sidebar from './Sidebar';
import { SpeedInsights } from "@vercel/speed-insights/react"

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <Sidebar />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
